import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const pc_hide = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const sp_hide = css`
	@media (max-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const no_margin_top = css`
	margin-top: 0;
`

export const container = css`
	padding-top: 20px;
	padding-bottom: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
`

export const article = css`
	padding: 20px 0;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 40px 20px;
	}
	@media (min-width: ${breakpoints.pc}px) {
		padding: 40px 0;
	}
`

export const article_flex = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
		justify-content: space-between;
		align-items: start;
	}
`

export const article_flex_reverse = css`
	@media (min-width: ${breakpoints.tablet}px) {
		flex-direction: row-reverse;
	}
`

export const article_main = css`
	position: relative;
	@media (min-width: ${breakpoints.tablet}px) {
		width: calc(58% - 30px);
	}
`

export const article_sub = css`
	@media (min-width: ${breakpoints.tablet}px) {
		width: calc(42% - 30px);
	}
`

export const article_note = css`
	margin-top: 20px;
	
	border-radius: 8px;
	padding: 20px;
`

export const article_note_text = css`
	margin-top: 10px;
	
	font-weight: 500;
	font-size: 1.4rem;
	padding-left: 1.4rem;
	text-indent: -1.4rem;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 1.6rem;
	}
`

export const article_category_text = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
	}
`

export const article_title_text = css`
	margin-top: 10px;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 3.2rem;
	}
`

export const article_description_text = css`
	margin-top: 10px;
	
	font-weight: 500;
	font-size: 1.6rem;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
		
		font-size: 2rem;
	}
`

export const rounded = css`
	overflow: hidden;
	border-radius: 12px;
	@media (min-width: ${breakpoints.tablet}px) {
		border-radius: 20px;
	}
`

export const bg_image_border = css`
	border: 1px solid #dfe5ea;
`

export const bg_image_p_top = css`
	padding-top: 20px;
	
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 46px;
	}
`

export const bg_image_p_bottom = css`
	padding-bottom: 20px;

	@media (min-width: ${breakpoints.tablet}px) {
		padding-bottom: 46px;
	}
`

export const article_image = css`
	height: 223px;
	@media (min-width: ${breakpoints.tablet}px) {
		height: 427px;
	}
`

export const article_bg_icon = css`
	width: 40px;
	position: absolute;
	top: 20px;
	left: 20px;
	path {
		fill: ${palette.text.secondary};
	}
`

export const bg_grey = css`
	background-color: ${palette.grey['500']};
`

export const list_contents = css`
	background-color: ${palette.grey['500']};
	margin-bottom: 1px;
	padding: 13px 16px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 11px 16px;
		display: flex;
	}
`

export const list_text = css`
	margin-top: 10px;
	
	font-weight: 500;
	font-size: 1.4rem;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
		
		font-size: 1.6rem;
	}
`

export const list_contents_first = css`
	border-radius: 8px 8px 0 0;
`

export const list_contents_end = css`
	border-radius: 0 0 8px 8px;
	margin-bottom: 0;
`

export const list_title = css`
	width: 40%;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 1.6rem;
	}
`

export const list_icon = css`
	width: 20px;
	margin-right: 10px;
`

export const feature_list = css`
	margin-top: 20px;
`

export const feature_list_contents = css`
	background-color: ${palette.grey['500']};
	margin-bottom: 1px;
	padding: 12px 14px;
	display: flex;
`

export const feature_list_text = css`
	font-size: 1.4rem;
	font-weight: bold;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 1.6rem;
	}
`

export const app_logo = css`
	width: 64px;
	height: 64px;
	padding: 12px 14px;
	border: 1px solid ${palette.grey['500']};
	border-radius: 12px;
	display: inline-block;
`

export const app_hero = css`
	margin-top: 40px;
	
	height: 287px;
	background-size: contain;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 36px;
		margin-bottom: -80px;
		
		height: 630px;
		:before, :after {
			background-position: bottom !important;
		}
	}
`

export const app_store_logo = css`
	margin-top: 20px;
	
	width: 130px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;
	}
`

export const app_category_text =css`
	margin-top: 20px;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 18px;
	}
`

export const app_catch_text = css`
	margin-top: 10px;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 19px;
	}
`

export const app_subtitle_text = css`
	margin-top: 10px;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}
`

export const app_description_text = css`
	margin-top: 10px;
	
	font-weight: 500;
	font-size: 1.6rem;
`

export const app_note_text = css`
	margin-top: 11px;
	
	font-weight: 500;
	font-size: 1.2rem;
`

export const app_contents = css`
	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
	}
`

export const store_logo = css`
	margin-top: 20px;
	
	width: 130px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;
		
		width: 230px;
	}
`

export const bath_graph_container = css`
	margin-top: 20px;
	margin-left: -20px;
	margin-right: -20px;
	border-radius: 0;
	@media (min-width: ${breakpoints.tablet}px) {
		margin: 0;
		border-radius: 12px;
	}
`

export const bath_graph_note = css`
	margin-top: 10px;
	text-align: right;
	
	font-weight: 500;
	font-size: 1.2rem;
`

export const concept_flex = css`
	display: flex;
	justify-content: space-around;
`

export const concept_card = css`
	width: calc(92% / 3);
	text-align: center;
`

export const concept_icon = css`
	width: 40px;
	height: 40px;
	margin-left: auto;
	margin-right: auto;
	@media (min-width: ${breakpoints.tablet}px) {
		width: 80px;
		height: 80px;
	}
`

export const concept_text = css`
	margin-top: 10px;
	font-size: 1.2rem;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
		font-size: 1.6rem;
	}
`

export const recommend = css`
	background: ${palette.primary.main};
`

export const recommend_form_container = css`
	padding: 0 20px;
	@media (min-width: ${breakpoints.pc}px) {
		margin: 0 auto;
		max-width: 1044px;
		overflow: hidden;
	}
`

export const article_btn = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
	}
`

export const app_title_text = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0px;
	}
`

export const bgm_list = css`
	margin-top: 20px;
`

export const literature_list = css`
	margin-top: 20px;
`

export const function_list = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0px;
	}
`

export const list_x_scroll = css`
	display: flex;
	overflow-x: scroll;
	margin-top: 20px;
	:after {
		min-width: 20px;
		height: 1px;
		content: '';
	}
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
		justify-content: space-between;
		:after {
			display: none;
		}
	}
`

export const news_card = css`
	margin-right: 10px;
	width: calc(100% - 45px);
	min-width: calc(100% - 45px);
	@media (min-width: ${breakpoints.tablet}px) {
		min-width: auto;
		width: 334px;
	}
`

export const header_container = css`
	position: relative;
	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
	}
`

export const news_title = css`
	margin-top: 10px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}
`

export const news_link_text = css`
	position: absolute;
	right: 0;
	bottom: 0;
`