import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const container = css`
	padding: 20px;
	border: 1px solid ${palette.grey[500]};
	border-radius: 20px;
`

export const grid_list = css`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 20px;
`