import React from 'react'

import { container } from './styles'

const CardLabel: React.FC = ({ children, ...props }) => {
	return (
		<span css={container} {...props}>
			{children}
		</span>
	)
}

export default CardLabel