import React from 'react'
import PLAY_BUTTON from '~/images/play-button.svg'

import { container } from './styles'

const PlayButton: React.FC = ({ ...props }) => {
	return (
		<div css={container} {...props}>
			<img src={PLAY_BUTTON} alt='' />
		</div>
	)
}

export default PlayButton