import React from 'react'
import Img, { FluidObject } from 'gatsby-image'

import Typography from '~/components/atoms/Typography'
import CardLabel from '~/components/atoms/CardLabel'

import { container, content, label, media, content_title, content_description } from './styles'

type Props = {
	img: FluidObject
	tag: string
	title: string
	description: string
}

const LiteratureCard: React.FC<Props> = ({ img, tag, title, description, ...props }) => {
	return (
		<div css={container} {...props}>
			<div css={media}>
				<Img fluid={img} />
				<CardLabel css={label}>{tag}</CardLabel>
			</div>
			<div css={content}>
				<Typography color='textPrimary' css={content_title}>{title}</Typography>
				<Typography css={content_description}>{description}</Typography>
			</div>
		</div>
	)
}

export default LiteratureCard