import React from 'react'
import Img, { FluidObject } from 'gatsby-image'

import PlayButton from '~/components/atoms/PlayButton'

import { container, play_btn, bgm_title } from './styles'

type Props = {
	img: FluidObject
	title: string
}

const BGMCard: React.FC<Props> = ({ img, title, ...props }) => {
	return (
		<div css={container} {...props}>
			<Img fluid={img} />
			<PlayButton css={play_btn} />
			<p dangerouslySetInnerHTML={{__html: title}} css={bgm_title}/>
		</div>
	)
}

export default BGMCard