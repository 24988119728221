import React from 'react'
import App from '~/components/pages/App'
import SEO from '~/utils/seo'
import { PageProps } from 'gatsby'

export default function AppPage({ location }: PageProps) {
    return (
        <>
            <SEO
                title='【入浴アプリ Onsen*】入浴レシピと癒しの音楽で毎日の睡眠と自律神経ケアの温泉アプリ | Onsen*(オンセン)'
                description='アプリと専用入浴剤が連携。いつものお風呂がスパ空間に。入浴から入眠まで最高の体験を届けるためのアプリ。最適な入浴時間、入眠タイミングをサポートするタイマー＆通知機能。「ぐっすり眠りたい」「自律神経が乱れている」などのお悩みを入浴で解決します。色を変えられる入浴ライト機能や、ヒーリング曲などにより、視覚や聴覚からもリラックス気分をお届け。'
                url={location.href}
            />
            <App />
        </>
    )
}
