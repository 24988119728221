import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const container = css`
	border: 1px solid ${palette.grey['500']};
	border-radius: 8px;
`

export const media = css`
	position: relative;
`

export const content = css`
	padding: 8px;
`

export const label = css`
	position: absolute;
	left: 8px;
	bottom: 6px;
`

export const content_title = css`
	font-size: 1.2rem;
`

export const content_description = css`
	margin-top: 4px;
	font-size: 8px;
	font-weight: normal;
`