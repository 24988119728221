import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Typography from '~/components/atoms/Typography'
import BGMCard from '~/components/molecules/BGMCard'

import { caption_text, container, grid_list } from './styles'

const BGMList: React.FC = ({ ...props }) => {
	const {
		hikarinimukatte,
		kagaribinohiiringu,
		keiryuunohiiringu,
		nokisitanoame,
	} = useStaticQuery<GatsbyTypes.BGMListQuery>(graphql`
		query BGMList {
			hikarinimukatte: file(relativePath: {eq: "bgm/piano@3x.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 360) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    kagaribinohiiringu: file(relativePath: {eq: "bgm/mix_kagaribi@3x.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 360) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    keiryuunohiiringu: file(relativePath: {eq: "bgm/mix_keiryu@3x.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 360) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    nokisitanoame: file(relativePath: {eq: "bgm/ame_nokishitano-ame@3x.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 360) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		}
	`)
	return (
		<div css={container} {...props}>
			<ul css={grid_list}>
				<li>
					<BGMCard
						img={nokisitanoame.childImageSharp.fluid}
						title={'軒下の雨'}
					/>
				</li>
				<li>
					<BGMCard
						img={hikarinimukatte.childImageSharp.fluid}
						title={'光に向かって'}
					/>
				</li>
				<li>
					<BGMCard
						img={keiryuunohiiringu.childImageSharp.fluid}
						title={`渓流の<br/>ヒーリング`}
					/>
				</li>
				<li>
					<BGMCard
						img={kagaribinohiiringu.childImageSharp.fluid}
						title={`かがり火の<br/>ヒーリング`}
					/>
				</li>
			</ul>
			<Typography color='textSecondary' css={caption_text}>他にも20種類以上のBGMをインストール</Typography>
		</div>
	)
}

export default BGMList