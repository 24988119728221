import React, { FC } from 'react'
import Typography from '~/components/atoms/Typography'

import {
	header,
	bar,
	circle,
	bar_container,
	start_circle,
	end_circle,
	middle_circle,
	fig1_container,
	fig1_header,
	fig1_center_text,
	fig1_right_text,
	fig1_footer,
	fig1_icon,
	fig1_footer_text,
	fig2_container,
	fig2_icon,
	notice_box,
	notice_patch,
	fig2_left,
	container,
	fig2_text_container,
	header_title,
	header_sub_text, fig2_text,
} from './styles'

import THERMOMETER from '~/images/thermometer-icon.svg'
import APP from '~/images/app.svg'

const BathGraphFigure: FC = ({...props}) => {
	return (
		<div css={container} {...props}>
			{/* 入浴 -> 安眠 */}
			<div css={header}>
				<Typography css={header_title}>入浴</Typography>
				<Typography color='textSecondary' css={header_sub_text}>10-15min.</Typography>
				<Typography css={header_title}>お風呂上り</Typography>
				<Typography color='textSecondary' css={header_sub_text}>90min.</Typography>
				<Typography css={header_title}>安眠</Typography>
			</div>
			{/*　line */}
			<div css={bar_container}>
				<div css={bar} />
				<div css={[circle, start_circle]}/>
				<div css={[circle, middle_circle]}/>
				<div css={[circle, end_circle]}/>
			</div>
			{/* 図 */}
			<div css={fig1_container}>
				<div css={fig1_header}>
					<svg viewBox="0 0 297 67" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M2 64.746C2 64.746 91.975 1.99996 148.394 2C204.813 2.00004 184.854 64.746 297 64.746" stroke="#3BB7A9" strokeWidth="4"/>
					</svg>
					<Typography css={fig1_center_text}>+0.5℃</Typography>
					<Typography css={fig1_right_text} color='textSecondary'>Zz..</Typography>
				</div>
				<div css={fig1_footer}>
					<img src={THERMOMETER} alt='' css={fig1_icon} />
					<Typography css={fig1_footer_text} variant='body2'>・上昇した体温が下がると自然な眠気が訪れます。</Typography>
				</div>
			</div>
			{/*	図 */}
			<div css={fig2_container}>
				<img src={APP} alt='' css={fig2_icon} />
				<div css={fig2_left}>
					<div css={fig2_text_container}>
						<Typography variant='body2'>・環境音でリラックス</Typography>
						<Typography variant='body2' css={fig2_text}>・気分に合わせた入浴レシピ</Typography>
					</div>
					<div css={notice_box}>
						<div css={notice_patch}/>
						<Typography variant='body2'>最適な就寝タイミングを通知</Typography>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BathGraphFigure