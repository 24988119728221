import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const container = css`
	border-radius: 8px;
	overflow: hidden;
	position: relative;
`

export const play_btn = css`
	position: absolute;
	top: 12px;
	right: 12px;
`

export const bgm_title = css`
	position: absolute;
	bottom: 8px;
	left: 12px;
	
	font-size: 1.6rem;
	line-height: 128%;
	color: ${palette.common.white};
`