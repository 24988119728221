import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import LiteratureCard from '~/components/molecules/LiteratureCard'

import { container, grid_list } from './styles'

const LiteratureList: React.FC = ({ ...props }) => {
	const {
		kumonoito,
		mikan,
		remon,
	} = useStaticQuery<GatsbyTypes.LiteratureListQuery>(graphql`
		query LiteratureList {
			kumonoito: file(relativePath: {eq: "literature/rodoku_kumo@3x.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 360) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    mikan: file(relativePath: {eq: "literature/rodoku_mikan@3x.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 360) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    remon: file(relativePath: {eq: "literature/rodoku_remon@3x.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 360) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		}
	`)
	return (
		<div css={container} {...props}>
			<ul css={grid_list}>
				<li>
					<LiteratureCard
						img={remon.childImageSharp.fluid}
						tag={'20分間の入浴'}
						title={'梶井基次郎「檸檬」'}
						description={'色彩豊かな、陰鬱な青年の１日'}
					/>
				</li>
				<li>
					<LiteratureCard
						img={mikan.childImageSharp.fluid}
						tag={'13分間の入浴'}
						title={'芥川龍之介「蜜柑」'}
						description={'少女と蜜柑の、心温まる短編'}
					/>
				</li>
				<li>
					<LiteratureCard
						img={kumonoito.childImageSharp.fluid}
						tag={'10分間の入浴'}
						title={'芥川龍之介「蜘蛛の糸」'}
						description={'一本の蜘蛛の糸が紡ぐ物語'}
					/>
				</li>
			</ul>
		</div>
	)
}

export default LiteratureList