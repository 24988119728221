import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const container = css`
	padding: 20px;
	border: 1px solid ${palette.grey[500]};
	border-radius: 20px;
`

export const grid_list = css`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 20px;
`

export const caption_text = css`
	margin-top: 10px;
	
	text-align: center;
	font-weight: 500;
	font-size: 1.2rem;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}
`